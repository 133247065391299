import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Img from "gatsby-image"
import Arrow from 'react-arrow'

const myinsights = ({data}) => (
    <Layout>
        <SEO title="CRM MyInsights Dashboard" />
        <div className="Hero">
            <div className="HeroImage">
                <Img fluid={data.myinsightsHero.childImageSharp.fluid} alt="CRM MyInsights Hero Image" />
             </div>
        </div>
        <div className="PageContent">
            <h1>CRM MyInsights Dashboard</h1>
            <h4>Time: 2017</h4>

            <h2>About</h2>
            <p> <a href="https://www.veeva.com/products/multichannel-crm/myinsights/">Veeva CRM MyInsights</a> is a data visualization capability inside Veeva CRM. It enables pharma field sales teams to plan and excute effectively with customizable data. I worked on the pre-built dashboard. This feature was released in Fall 2017.  </p>
            <div className="ImageWrapper">
                <Img fluid={data.myinsightsDetail.childImageSharp.fluid} alt="CRM MyInsights Dashboard Details" />
            </div>
        </div>

        <div className="BottomNav">
          <div className="leftNav" >
            <Link to="/crmmedia"><Arrow className="arrow"
            direction="left"
            shaftWidth={2}
            shaftLength={8}
            headWidth={10}
            headLength={8}
            />Previous project
            </Link>
          </div>
          <div className="rightNav">
            
          </div>
        </div>

    </Layout>
)

export default myinsights 

export const query = graphql`
query {
    myinsightsHero: file(relativePath: {eq: "myinsight-hero.png"}) {
        childImageSharp {
          fluid(maxWidth: 1112) {
           ...GatsbyImageSharpFluid
          }
        }
      }
      myinsightsDetail: file(relativePath: {eq: "myinsights-details.png"}) {
        childImageSharp {
          fluid(maxWidth: 1112) {
           ...GatsbyImageSharpFluid
          }
        }
      }  

}
`
